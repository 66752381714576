<script setup lang="ts">
import { mdiBookmark, mdiBookmarkOutline } from '@mdi/js';

const {
  $formatPostLink,
  $formatPriceWithCurrency,
  $formatTimeAgo,
  $formatLocation,
  $formatCategoryName,
} = useNuxtApp();

const props = withDefaults(
  defineProps<{
    isLoading: boolean;
    layout: 'row' | 'card';
    title: string;
    uuid: string;
    type: string;
    slug: string;
    category: string;
    price: number;
    priceOnApplication: boolean;
    currency: string;
    createdAt: number;
    county: County;
    town: Town;
    thumbnail: Record<string, any>;
    gallery: Record<string, any>[];
    premium: boolean;
    seller: Record<string, any>;
    showSpotlightBadge?: boolean;
    showBusinessCard?: boolean;
    showGalleryCount?: boolean;
    showFavourite?: boolean;
    showFavouritePosition?: 'image' | 'body';
    showMeta?: boolean;
  }>(),
  {
    layout: 'card',
  }
);

const { isAFavourite, toggleFavourite } = useFavourites({
  uuid: computed(() => props.uuid),
  type: computed(() => props.type),
  category: computed(() => props.category),
  county: computed(() => props.county),
});

const { authenticated } = storeToRefs(useAuthStore());
</script>

<template>
  <component
    :is="!isLoading ? defineNuxtLink({}) : 'div'"
    :class="[
      `post-card--${layout}`,
      {
        'post-card--spotlight': showSpotlightBadge && premium,
        'post-card--loading': isLoading,
      },
    ]"
    :to="!isLoading ? $formatPostLink(category, slug) : null"
    :layout="layout"
  >
    <template v-if="!isLoading && showBusinessCard && seller.type === 'trade'">
      <PostCardBusiness
        class="post-card__business"
        :name="seller.name"
        :logo="seller.logo"
      />
    </template>
    <div class="post-card__main">
      <div class="post-card__thumbnail">
        <template v-if="!isLoading">
          <HayThumbnail :src="thumbnail.image.src" :alt="thumbnail.image.alt" />
          <ImageOverlayContent
            v-if="showGalleryCount && gallery.length"
            class="post-card__count"
          >
            <GalleryCount :total="gallery.length" />
          </ImageOverlayContent>
          <ClientOnly>
            <ImageOverlayContent
              v-if="
                authenticated &&
                showFavourite &&
                showFavouritePosition === 'image'
              "
              class="post-card__favourite"
              @click.prevent="toggleFavourite"
            >
              <BaseSvgIcon
                :is="isAFavourite ? mdiBookmark : mdiBookmarkOutline"
                type="mdi"
              />
            </ImageOverlayContent>
          </ClientOnly>
        </template>
        <HayPostBadge
          v-if="showSpotlightBadge && premium"
          class="post-card__badge"
          label="Spotlight"
        />
      </div>
      <div class="post-card__content">
        <div :class="`post-card__spacer--${showMeta ? 'meta' : 'no-meta'}`">
          <h2 class="post-card__title">
            {{ title }}
          </h2>
          <div v-if="showMeta" class="post-card__meta">
            <template v-if="!isLoading">
              <div class="post-card__location">
                {{ $formatLocation(county, town) }}
              </div>
              <div class="post-card__category">
                {{ $formatCategoryName(category) }}
              </div>
            </template>
          </div>
        </div>
        <div class="post-card__about">
          <div class="post-card__price">
            <template v-if="!isLoading">
              <div>
                <span>Price</span>
                <template v-if="priceOnApplication">POA</template>
                <template v-else>
                  {{ $formatPriceWithCurrency(price, currency) }}
                </template>
              </div>
              <div
                v-if="
                  authenticated &&
                  showFavourite &&
                  showFavouritePosition === 'body'
                "
                @click.prevent="toggleFavourite"
              >
                <BaseSvgIcon
                  :is="isAFavourite ? mdiBookmark : mdiBookmarkOutline"
                  type="mdi"
                />
              </div>
            </template>
          </div>
          <div class="post-card__time">
            {{ $formatTimeAgo(createdAt) }}
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<style scoped lang="scss">
.post-card {
  $this: &;

  @include box-shadow;
  @include rounded;

  text-decoration: none;

  background: color(neutral, 100);

  width: 100%;

  display: block;
  overflow: hidden;
  &--loading {
    #{$this}__thumbnail,
    #{$this}__title,
    #{$this}__price,
    #{$this}__meta {
      @include animated-background;
    }
    #{$this}__thumbnail {
      border-radius: 4px;

      &:before {
        content: '';

        height: 0;
        padding-top: 75%;

        display: block;
      }
    }
    #{$this}__title {
      height: 20px;
      max-width: 250px;
    }

    #{$this}__price,
    #{$this}__meta {
      min-height: 16px;
    }
    #{$this}__price {
      max-width: 50px;
    }
    #{$this}__meta {
      max-width: 200px;
    }
  }
  &--row,
  &--card {
    @extend #{$this};
  }
  &--row {
    @include media-breakpoint-up(md) {
      #{$this}__main {
        grid-template-columns: 1fr 300px;
      }
      #{$this}__content {
        order: 1;
      }
      #{$this}__title {
        font-size: 20px;
      }
      #{$this}__price {
        font-size: 18px;
        span {
          font-size: 14px;
        }
      }
      #{$this}__meta {
        margin-top: 16px;

        font-size: 16px;
      }
    }

    #{$this}__business {
      border-bottom: 1px solid var(--border-color, color(neutral, 300));

      grid-column: span 2;
    }
    #{$this}__thumbnail {
      @include media-breakpoint-up(md) {
        order: 2;
      }
    }
  }
  &--card {
    @include media-breakpoint-up(sm) {
      #{$this}__business {
        display: none;
      }
    }
    #{$this}__spacer {
      &--meta {
        min-height: 90px;
      }
      &--no-meta {
        min-height: 50px;
      }
    }
  }
  &--spotlight {
    position: relative;
    #{$this}__business {
      border-bottom: 3px solid color(neutral, 250);
    }
    #{$this}__main {
      border-top: 3px solid color(primary, 650);
    }
  }
  &__main {
    height: 100%;

    display: grid;

    grid-template-rows: auto 1fr;
  }
  &__content {
    padding: 16px;

    display: flex;
    flex-direction: column;

    order: 2;
    justify-content: space-between;
  }
  &__thumbnail {
    position: relative;

    order: 1;
  }
  &__count,
  &__favourite {
    bottom: 15px;
    position: absolute;
  }
  &__count {
    left: 15px;
  }
  &__favourite {
    color: color(neutral, 100);

    right: 15px;
  }
  &__title {
    color: color(neutral, 750);

    margin: 0 0 8px;

    overflow: hidden;

    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
  }

  &__meta {
    color: color(neutral, 650);

    margin-top: 8px;

    display: flex;

    font-size: 12px;

    flex-wrap: wrap;
    align-items: center;
    & > div {
      display: flex;

      align-items: center;
      & + div {
        margin-left: 4px;

        position: relative;
        &:before {
          content: '•';

          color: color(neutral, 350);

          margin-right: 4px;

          font-size: 20px;
        }
      }
    }
  }
  &__about {
    margin-top: 15px;

    display: flex;

    justify-content: space-between;
  }
  &__price {
    color: color(neutral, 650);

    display: flex;

    font-size: 16px;
    font-weight: 700;

    align-items: flex-end;
    justify-content: space-between;
    span {
      color: color(neutral, 550);

      margin-bottom: 4px;

      display: block;

      font-size: 12px;
      font-weight: 500;
    }
  }
  &__time {
    text-align: right;

    color: #708f85;

    margin-top: 15px;

    display: flex;

    font-size: 11px;

    justify-content: space-between;
    align-items: flex-end;
  }
  &__badge {
    position: absolute;
    right: 32px;
    top: 0;
    z-index: 1;
  }
}
</style>
