import revive_payload_client_4sVQNw7RlN from "/home/runner/work/haystack-nuxt/haystack-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/haystack-nuxt/haystack-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/haystack-nuxt/haystack-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/haystack-nuxt/haystack-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/haystack-nuxt/haystack-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/haystack-nuxt/haystack-nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/haystack-nuxt/haystack-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/haystack-nuxt/haystack-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/home/runner/work/haystack-nuxt/haystack-nuxt/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/haystack-nuxt/haystack-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_GFfDXud5Cr from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/api.ts";
import capacitor_client_ZM4Yhs2uPi from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/capacitor.client.ts";
import capgo_client_kWXvTI4OED from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/capgo.client.ts";
import config_Lw1zr0Vi5r from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/config.ts";
import currency_gntxDfkfZG from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/currency.ts";
import dates_dMHmLGY8uE from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/dates.ts";
import mutations_IlU5ZuJ3c8 from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/mutations.ts";
import onesignal_client_FpFKSicGgh from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/onesignal.client.ts";
import posts_a2b1xDh9tD from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/posts.ts";
import primevue_7rYYRZQLyx from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/primevue.ts";
import queries_4kpvXB88cQ from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/queries.ts";
import routing_4D4PMB3cQ6 from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/routing.ts";
import sentry_3AyO8nEfhE from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/sentry.ts";
import text_sc37v2klBL from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/text.ts";
import universal_links_a8Oh0FROYS from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/universal-links.ts";
import vue_query_wrmMkNpEpe from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/vue-query.ts";
import youtube_6jDmIJ7Ryh from "/home/runner/work/haystack-nuxt/haystack-nuxt/plugins/youtube.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  api_GFfDXud5Cr,
  capacitor_client_ZM4Yhs2uPi,
  capgo_client_kWXvTI4OED,
  config_Lw1zr0Vi5r,
  currency_gntxDfkfZG,
  dates_dMHmLGY8uE,
  mutations_IlU5ZuJ3c8,
  onesignal_client_FpFKSicGgh,
  posts_a2b1xDh9tD,
  primevue_7rYYRZQLyx,
  queries_4kpvXB88cQ,
  routing_4D4PMB3cQ6,
  sentry_3AyO8nEfhE,
  text_sc37v2klBL,
  universal_links_a8Oh0FROYS,
  vue_query_wrmMkNpEpe,
  youtube_6jDmIJ7Ryh
]